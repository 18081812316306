import PaginatedGridGallery_PaginatedGridOverlayComponent from '../components/NativeGalleries/PaginatedGridGallery/viewer/skinComps/PaginatedGridOverlay/PaginatedGridOverlay.skin';
import PaginatedGridGallery_PaginatedGridOverlayController from '../components/NativeGalleries/PaginatedGridGallery/viewer/PaginatedGridGallery.controller';


const PaginatedGridGallery_PaginatedGridOverlay = {
  component: PaginatedGridGallery_PaginatedGridOverlayComponent,
  controller: PaginatedGridGallery_PaginatedGridOverlayController
};


export const components = {
  ['PaginatedGridGallery_PaginatedGridOverlay']: PaginatedGridGallery_PaginatedGridOverlay
};

